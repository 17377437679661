<template>
  <div class="logout-page">
    logout...
  </div>
</template>

<script>
import { clearAuth } from "@/common/auth.service";

export default {
  name: "Home",
  created() {
    clearAuth();
    this.$router.push("/");
  }
};
</script>
